/*
 * @Author: linjituan linjituan@revolution.com
 * @Date: 2024-01-26 10:28:54
 * @LastEditors: linjituan linjituan@revolution.com
 * @LastEditTime: 2024-01-26 11:24:17
 * @FilePath: \ra-web-admin\src\config\project\suizhou.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const project = {
  homeIntroduction: [
    '环境卫生智能化整体解决方案提供商。',
    '用智能美化环境是我们的职责所在，无人驾驶技术带来环卫运营服务的革命新未来。'
  ],
  systemName: '中国移动智驾云控平台',
  webTitle: '中国移动智驾',
  loadingTitle: '中国移动智驾',
  webIcon: require('@/assets/images/suizhou/favicon.png'),
  loginLogo: require('@/assets/images/suizhou/logo.png'),
  menuLogo: require('@/assets/images/suizhou/logo2.png'),
  slogan: '驶向环保新未来'
}

module.exports = project
