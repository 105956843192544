/*
 * @Auth: linjituan
 * @Date: 2022-08-08 11:33:48
 * @LastEditors: linjituan linjituan@revolution.com
 * @LastEditTime: 2023-07-19 20:37:20
 */
import { PropTypes, initDefaultProps } from './utils/index'
import './css/index.css'
import { Empty } from 'ant-design-vue'

export default {
  name: 'RaTable',
  inheritAttrs: false,
  model: {},
  components: {
    AEmpty: Empty
  },
  props: initDefaultProps(
    {
      data: PropTypes.array,
      dataSource: PropTypes.array,
      useFixedHeader: PropTypes.bool,
      columns: PropTypes.array,
      prefixCls: PropTypes.string,
      bodyStyle: PropTypes.object,
      rowKey: PropTypes.any,
      rowClassName: PropTypes.any,
      customRow: PropTypes.func,
      customHeaderRow: PropTypes.func,
      // onRowClick: PropTypes.func,
      // onRowDoubleClick: PropTypes.func,
      // onRowContextMenu: PropTypes.func,
      // onRowMouseEnter: PropTypes.func,
      // onRowMouseLeave: PropTypes.func,
      showHeader: PropTypes.bool,
      title: PropTypes.func,
      id: PropTypes.string,
      tid: PropTypes.string,
      footer: PropTypes.func,
      emptyText: PropTypes.any,
      scroll: PropTypes.object,
      rowRef: PropTypes.func,
      getBodyWrapper: PropTypes.func,
      expandIconAsCell: PropTypes.bool,
      expandedRowKeys: PropTypes.array,
      expandedRowClassName: PropTypes.func,
      defaultExpandAllRows: PropTypes.bool,
      defaultExpandedRowKeys: PropTypes.array,
      expandIconColumnIndex: PropTypes.number,
      expandedRowRender: PropTypes.func,
      childrenColumnName: PropTypes.string,
      indentSize: PropTypes.number,
      expandRowByClick: PropTypes.bool,
      bordered: PropTypes.bool,
      expandIcon: PropTypes.func,
      tableLayout: PropTypes.string,
      transformCellText: PropTypes.func,
      locale: PropTypes.object,
      loading: PropTypes.bool,
      size: PropTypes.string,
      sortDirections: PropTypes.any,
      rowSelection: PropTypes.object
    },
    {
      dataSource: [],
      useFixedHeader: false,
      rowSelection: null,
      size: 'default',
      loading: false,
      bordered: false,
      indentSize: 20,
      locale: {},
      rowKey: 'id',
      showHeader: true,
      sortDirections: ['ascend', 'descend'],
      childrenColumnName: 'children'
    }
  ),
  data() {
    return {
      tableData: [],
      tablePage: {
        currentPage: 1,
        pageSize: 10,
        totalResult: 0
      }
    }
  },
  created() {
    const path = this.$route.path
    this.tableId = path.replace(/\//g, '-') + '-' + this.tid
    window['vxeTable-' + this.tableId] = this.connectToolbar
    this.setTableData(this.dataSource)
  },
  mounted() {
    console.log('ra-table this', this)
    this.connectToolbar()
  },
  watch: {
    dataSource: {
      handler(val, oldVal) {
        // const oldTableData = JSON.parse(JSON.stringify(this.tableData))
        // console.log('watch Data ***', val)
        this.tableData = []
        this.setTableData(val)
        // console.log('watch tabelData ***', this.tableData)
        if (this.$refs.xTable && this.$refs.xTable.getTreeExpandRecords) {
          const treeExpandRecords = JSON.parse(JSON.stringify(this.$refs.xTable.getTreeExpandRecords()))
          console.log('treeExpandRecords', treeExpandRecords)
          this.setTeeExpand(treeExpandRecords)
        }
        // this.checkDataSource(this.tableData, oldTableData)
      },
      deep: true
    }
  },
  methods: {
    connectToolbar() {
      this.$nextTick(() => {
        // 手动将表格和工具栏进行关联
        const tuid = window.currentToorbarUid[this.tid]
        const p = this.getParentNode(this.$parent, tuid)
        for (const item of p.$children) {
          if (item.id === p._uid + (this.tid || '')) {
            console.log('item ***', item)
            this.$refs.xTable.connect(item.$refs.xToolbar)
            break
          }
        }
      })
    },
    getParentNode(p, tuid) {
      if (p._uid === tuid) {
        return p
      } else if (p.$parent) {
        return this.getParentNode(p.$parent, tuid)
      } else {
        console.error('当前未找到相同的父级uid')
      }
    },
    handlePageChange({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage
      this.tablePage.pageSize = pageSize
    },
    setTableData(data = [], id) {
      let index = 0
      for (const item of data) {
        const obj = {}
        for (const key in item) {
          if (key !== 'children') {
            obj[key] = item[key]
          }
        }
        obj.VXEid = (id || '0') + '_' + index
        obj.VXEparentId = id || '0'
        this.tableData.push(obj)
        index++
        if (item.children) {
          this.setTableData(item.children, obj.VXEid)
        }
      }
    },
    selectChangeEvent({ checked }) {
      const records = this.$refs.xTable.getCheckboxRecords()
      console.log(checked ? '勾选事件' : '取消事件', records)
      const selectedRowKeys = []
      const selectedRows = []
      for (const item of records) {
        selectedRowKeys.push(item[this.rowKey])
        selectedRows.push(item)
      }
      this.rowSelection.onChange(selectedRowKeys, selectedRows)
    },
    checkDataSource(data, oldData) {
      // 修复上移下移时会收起父级树BUG
      if (oldData && oldData.length && oldData.length === data.length) {
        let parentId
        for (let i = data.length - 1; i >= 0; i--) {
          if (parentId && parentId === data[i].id) {
            console.log('checkDataSource ****', data[i])
            this.$refs.xTable.setTreeExpand(data[i], true)
          }
          if (!parentId && data[i].parentId && data[i].id !== oldData[i].id) {
            parentId = data[i].parentId
          }
        }
      }
    },
    setTeeExpand(treeExpandRecords) {
      // 修复上移下移时会收起父级树BUG
      for (const item of treeExpandRecords) {
        for (const tItem of this.tableData) {
          if (tItem[this.rowKey] === item[this.rowKey]) {
            this.$nextTick(() => {
              this.$refs.xTable.setTreeExpand(tItem, true)
            })
          }
        }
      }
    }
  },
  render(createElement) {
    // console.log('render ***', this.dataSource)
    let e = null
    const onEvents = {}
    const attrs = {
      data: this.tableData,
      resizable: true,
      loading: this.loading,
      customConfig: { storage: true },
      id: this.tableId,
      border: 'inner',
      'header-row-class-name': 'ra-table-header-row',
      'tree-config': {
        transform: true,
        rowField: 'VXEid',
        parentField: 'VXEparentId'
        // iconOpen: 'fa fa-minus-square-o',
        // iconClose: 'fa fa-plus-square-o'
      },
      'edit-config': { trigger: 'click', mode: 'cell' }
    }
    if (this.rowSelection) {
      onEvents['checkbox-change'] = this.selectChangeEvent
      onEvents['checkbox-all'] = this.selectChangeEvent
      e = createElement('vxe-column', {
        attrs: {
          type: 'checkbox',
          width: '60'
        }
      })
    }
    const vxScopedSlots = {}
    vxScopedSlots.empty = (scoped = {}) => {
      return createElement('a-empty', {
        attrs: {
          image: Empty.PRESENTED_IMAGE_SIMPLE
        }
      })
    }
    if (this.$scopedSlots.expandedRowRender) {
      if (
        this.columns.length &&
        (!this.columns[0].scopedSlots || this.columns[0].scopedSlots.customRender !== 'expandedRowRender')
      ) {
        this.columns.unshift({
          type: 'expand',
          scopedSlots: { customRender: 'expandedRowRender' },
          slotType: 'content',
          align: 'center',
          width: '60'
        })
      }
    }
    return (
      <div class="ra_table_wrap">
        {/* <vxe-toolbar size="mini" custom></vxe-toolbar> */}
        {createElement(
          'vxe-table',
          {
            attrs,
            on: onEvents,
            ref: 'xTable',
            autoResize: true,
            scopedSlots: vxScopedSlots,
            showHeaderOverflow: false,
            showOverflow: false
          },
          [
            e,
            ...this.columns.map((item, index) => {
              const scopedSlots = {}
              if (item.customRender) {
                scopedSlots.default = (scoped = {}) => {
                  return item.customRender(scoped.row[item.dataIndex], scoped.row, scoped.rowIndex)
                }
              } else if (item.scopedSlots && this.$scopedSlots[item.scopedSlots.customRender]) {
                scopedSlots[item.slotType || 'default'] = (scoped = {}) => {
                  return this.$scopedSlots[item.scopedSlots.customRender](
                    item.dataIndex ? scoped.row[item.dataIndex] : scoped.row,
                    scoped.row,
                    scoped.rowIndex
                  )
                }
              }
              return createElement('vxe-column', {
                attrs: {
                  field: item.dataIndex,
                  showHeaderOverflow: false, // item.ellipsis,
                  showOverflow: false, //  item.ellipsis ? 'title' : null,
                  title: item.title,
                  width: item.width,
                  minWidth: !item.width && 100,
                  visible: item?.visible,
                  align: item.align,
                  type: item.type,
                  'tree-node': !item.slotType && index === 0
                },
                scopedSlots
              })
            })
          ]
        )}
        {/* <vxe-table data={this.tableData} resizable={true} loading={this.loading}>
          {e}
          {eleArr.map((item) => {
            return item
          })}
          <vxe-column v-for="item in " title="Age3">
            <a-switch size="small" />
          </vxe-column>
        </vxe-table> */}
        {/* <vxe-pager
          background
          size="small"
          loading={this.loading}
          current-page={this.tablePage.currentPage}
          page-size={this.tablePage.pageSize}
          total={this.tablePage.totalResult}
          page-sizes={[10, 20, 30, 40]}
          layouts={['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']}
          on-page-change={this.handlePageChange}
        ></vxe-pager> */}
      </div>
    )
  }
}
