var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "my-layout",
    _vm._b(
      {
        attrs: {
          menus: _vm.menus,
          collapsed: _vm.collapsed,
          mediaQuery: _vm.query,
          isMobile: _vm.isMobile,
          handleMediaQuery: _vm.handleMediaQuery,
          handleCollapse: _vm.handleCollapse,
          i18nRender: _vm.i18nRender,
        },
        scopedSlots: _vm._u([
          {
            key: "menuHeaderRender",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    style:
                      "width: " + (_vm.$project.menuLogoWidth || 132) + "px;",
                  },
                  [
                    _c("img", {
                      staticClass: "logo loayoutMenuLogo",
                      attrs: { src: _vm.$project.menuLogo, alt: "logo" },
                    }),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "menuAvatarRender",
            fn: function () {
              return [
                _c("avatar-menu", {
                  attrs: {
                    "current-user": _vm.currentUser,
                    collapsed: !_vm.collapsed,
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "rightContentRender",
            fn: function () {
              return [
                _c("right-content", {
                  attrs: {
                    "top-menu": _vm.settings.layout === "topmenu",
                    "is-mobile": _vm.isMobile,
                    theme: _vm.settings.theme,
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "footerRender",
            fn: function () {
              return [_c("global-footer")]
            },
            proxy: true,
          },
        ]),
      },
      "my-layout",
      _vm.settings,
      false
    ),
    [
      _c(
        "a-layout-content",
        { style: { height: "100%", margin: "0 0 0px 0" } },
        [
          _vm.settings.multiTab ? _c("multi-tab") : _vm._e(),
          _c("transition", { attrs: { name: "page-transition" } }),
        ],
        1
      ),
      _vm.isDevEnv
        ? _c("setting-drawer", {
            attrs: { settings: _vm.settings },
            on: { change: _vm.handleSettingChange },
          })
        : _vm._e(),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }